import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, FormGroup, FormControlLabel, Typography } from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';
import { withErrorBoundary } from 'react-error-boundary';
import { find, isEmpty } from 'lodash';
import { Button } from 'cprime-foundational-components';
import { useCascadingFilterContext } from 'contexts/CascadingFilters.context';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import {
  CascadingFiltersHeader,
  CascadingFiltersListSection,
  CascadingFiltersSection,
} from 'components/CascadingFilters/components';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import * as S from './CascadingFilters.styles';

const CascadingFilters = ({
  applyFiltersHandler,
  cascadingFiltersConfig,
  cascadingFiltersLocal,
  cascadingOptions,
  getChipLabel,
  getFilterLists,
  getListOptions,
  hasUncheckedSection,
  isCascadingFilterOff,
  setCascadingFiltersLocal,
  setCascadingOptions,
  setIsCascadingFilterOff,
}) => {
  const { data: pageFilterData } = useCascadingFilterContext();

  const [openSection, setOpenSection] = useState();
  const [listSectionData, setListSectionData] = useState(null);

  const setInitialCascadingFilters = ({ cascadingFiltersInitial }) =>
    setCascadingOptions(
      getFilterLists({
        pageFilterData,
        storedFilters: cascadingFiltersInitial,
        isCascadingFilterOff,
      }),
    );

  const resetAllHandler = () => {
    setCascadingFiltersLocal([]);
    setInitialCascadingFilters({ cascadingFiltersInitial: {} });
    setOpenSection();
  };
  const sideFiltersComponent = {
    CascadingFiltersSection,
    CascadingFiltersHeader,
  };
  const getComponentByType = (name) => sideFiltersComponent[name] || null;

  const sectionParameters = {
    setCascadingFilters: setCascadingOptions,
    cascadingFilters: cascadingOptions,
    setCascadingFiltersLocal: setCascadingFiltersLocal,
    cascadingFiltersLocal: cascadingFiltersLocal,
    setListSectionData: setListSectionData,
    cascadingFiltersConfig: cascadingFiltersConfig,
    isCascadingFilterOff: isCascadingFilterOff,
  };

  return (
    <S.StyledBox sx={{ width: { mobile: '12rem', tablet: '16rem' } }}>
      {listSectionData ? (
        <CascadingFiltersListSection
          headerName={listSectionData.headerName}
          options={getListOptions({ headerName: listSectionData.headerName })}
          applyFiltersHandler={applyFiltersHandler}
          resetAllHandler={resetAllHandler}
          hasUncheckedSection={hasUncheckedSection}
          {...sectionParameters}
        />
      ) : (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            p="1rem"
            bgcolor="primary.main"
          >
            <Typography variant="body2" color="text.white">
              Global Filters
            </Typography>
            <PublicIcon fontSize="small" sx={{ color: 'text.white' }} />
          </Stack>
          {isEmpty(cascadingOptions) ? (
            <LoadingSpinner fullScreen />
          ) : (
            <>
              <Stack
                p="1rem"
                direction="row"
                sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
              >
                <FormGroup>
                  <FormControlLabel
                    variant="body2"
                    sx={{ height: '1rem' }}
                    control={
                      <S.StyledFilterSwitch
                        checked={!isCascadingFilterOff}
                        onClick={() => setIsCascadingFilterOff(!isCascadingFilterOff)}
                      />
                    }
                    label={<Typography variant="body2">Cascading Filters</Typography>}
                  />
                </FormGroup>
              </Stack>
              <Box
                sx={{
                  maxHeight: 'calc(100% - 10rem)',
                  overflow: 'auto',
                }}
              >
                {cascadingOptions.map((cascadingFilterObj) => {
                  const cascadingFilter = find(cascadingOptions, {
                    field: cascadingFilterObj.field,
                  });
                  const SideFilterSection = getComponentByType(cascadingFilterObj.type);
                  return (
                    SideFilterSection && (
                      <SideFilterSection
                        openSection={openSection}
                        setOpenSection={setOpenSection}
                        options={cascadingFilter.options}
                        key={cascadingFilterObj.field}
                        {...cascadingFilterObj}
                        {...sectionParameters}
                        getChipLabel={getChipLabel}
                      />
                    )
                  );
                })}
              </Box>
              <Stack p="1rem" spacing={1} direction="row">
                <Button variant="tertiary" onClick={() => resetAllHandler()}>
                  Reset All
                </Button>
                <Button
                  variant="primary"
                  onClick={() => applyFiltersHandler({})}
                  disabled={hasUncheckedSection()}
                >
                  Apply Filters
                </Button>
              </Stack>
            </>
          )}
        </>
      )}
    </S.StyledBox>
  );
};

CascadingFilters.propTypes = {
  applyFiltersHandler: PropTypes.func,
  cascadingFiltersConfig: PropTypes.array,
  cascadingFiltersLocal: PropTypes.array,
  cascadingOptions: PropTypes.array,
  getChipLabel: PropTypes.func,
  getFilterLists: PropTypes.func,
  getListOptions: PropTypes.func,
  hasUncheckedSection: PropTypes.func,
  isCascadingFilterOff: PropTypes.bool,
  setCascadingFiltersLocal: PropTypes.func,
  setCascadingOptions: PropTypes.func,
  setIsCascadingFilterOff: PropTypes.func,
};

export default withErrorBoundary(CascadingFilters, {
  fallback: <AlertMessage />,
});
