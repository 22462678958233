import { gql } from '@apollo/client';

export const PAGE_FILTER = gql`
  query {
    PageFilter {
      quickFilters {
        name
        value
      }
      fleetIndicators {
        name
        value
      }
      modelYears {
        name
        value
      }
      distributorCodes {
        distributorCode
        distributorName
        brandCodes {
          brandCode
          brandName
          regions {
            regionCode
            regionName
            dealers {
              dealerCode
              dealerName
              vehicleAssignmentIndicator
            }
          }
          saleSeriesNames {
            saleSeriesName
          }
        }
      }
    }
  }
`;
