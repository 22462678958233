import { gql } from '@apollo/client';

export const PAGE_FILTER_EXPORT = gql`
  query {
    PageFilterExport {
      quickFilters {
        name
        value
      }
      modelYears {
        name
        value
      }
      distributorCodes {
        distributorCode
        distributorName
        brandCodes {
          brandCode
          brandName
          countries {
            countryCode
            countryName
            modelNames {
              modelName
            }
          }
        }
      }
    }
  }
`;
