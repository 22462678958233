import React from 'react';
import PropTypes from 'prop-types';
import { List, Typography } from '@mui/material';
import { find, findIndex, filter, includes, isEmpty } from 'lodash';
import { AutoSizer, List as VirtualizedList } from 'react-virtualized';
import { useIsMobile } from 'hooks';
import CascadingFiltersListItem from '../CascadingFiltersListItem/CascadingFiltersListItem';
import EllipsisWithTooltip from '../../../EllipsisWithTooltip/EllipsisWithTooltip';

const isCheckedHandler = ({ sectionCascadingFiltersLocal, field, value }) => {
  if (isEmpty(sectionCascadingFiltersLocal)) {
    if (field === 'quickFilters') {
      return false;
    }
    return true;
  } else {
    const fieldObj = find(sectionCascadingFiltersLocal, { field, value });
    return fieldObj ? fieldObj.isChecked : false;
  }
};

const getNumOfSectionCheckedItems = (sectionCascadingFiltersLocal, numOfSectionItems, options) => {
  const uncheckedItems = filter(sectionCascadingFiltersLocal, {
    isChecked: false,
  });
  if (!isEmpty(uncheckedItems)) {
    return numOfSectionItems - uncheckedItems.length;
  }
  return options.length;
};
const getOption = (displayName) => (
  <EllipsisWithTooltip>
    <Typography
      component="label"
      variant="body4"
      textOverflow="ellipsis"
      overflow="hidden"
      whiteSpace={'nowrap'}
    >
      {displayName}
    </Typography>
  </EllipsisWithTooltip>
);

const CascadingFiltersListItemAll = ({
  options,
  cascadingFiltersConfig,
  isAllSectionItemsChecked,
  isAllIntermediate,
  setAllCheckbox,
}) => {
  const config = cascadingFiltersConfig?.find((config) => config.field === options[0].field);
  if (config?.hideAllOption) {
    return;
  }

  return (
    <CascadingFiltersListItem
      isChecked={isAllSectionItemsChecked}
      isIndeterminate={isAllIntermediate}
      itemText={`All (${options.length})`}
      setCheckbox={() => setAllCheckbox()}
      disableRipple
      size="small"
      sx={{
        '&.MuiButtonBase-root': {
          height: '1.5rem',
        },
      }}
    />
  );
};
CascadingFiltersListItemAll.propTypes = {
  options: PropTypes.array,
  cascadingFiltersConfig: PropTypes.array,
  isAllSectionItemsChecked: PropTypes.bool,
  isAllIntermediate: PropTypes.bool,
  setAllCheckbox: PropTypes.func,
};

const cloneAndRemoveUpstream = ({
  sectionFiled,
  cascadingFiltersLocal,
  cascadingFiltersConfig,
}) => {
  const sectionsToRemove = [];
  cascadingFiltersConfig.reduce(
    (prev, current) => {
      if (prev.passedCurrentSection) {
        sectionsToRemove.push(current.field);
        return { passedCurrentSection: true };
      }
      return { passedCurrentSection: sectionFiled === current.field };
    },
    { passedCurrentSection: false },
  );
  if (!isEmpty(sectionsToRemove)) {
    return filter(cascadingFiltersLocal, ({ field }) => !includes(sectionsToRemove, field));
  }
  return [...cascadingFiltersLocal];
};

export const CascadingFiltersList = ({
  options,
  fixedHeight,
  isFiltered,
  setCascadingFiltersLocal,
  cascadingFiltersLocal,
  virtualizedList,
  cascadingFiltersConfig,
  isCascadingFilterOff,
}) => {
  const isMobile = useIsMobile();

  if (isEmpty(options)) {
    return null;
  }
  const sectionFiled = options[0].field;
  const sectionCascadingFiltersLocal = filter(cascadingFiltersLocal, { field: sectionFiled });

  const setCheckboxHandler = ({ fieldObj, isChecked }) => {
    const { field, value } = fieldObj;
    const cascadingFiltersLocalClone = isCascadingFilterOff
      ? [...cascadingFiltersLocal]
      : cloneAndRemoveUpstream({
          sectionFiled,
          cascadingFiltersLocal,
          cascadingFiltersConfig,
        });

    const fieldIndex = findIndex(cascadingFiltersLocalClone, { field, value });

    if (fieldIndex > -1) {
      cascadingFiltersLocalClone[fieldIndex] = {
        field,
        value,
        isChecked: !isChecked,
      };
      setCascadingFiltersLocal(cascadingFiltersLocalClone);
    } else {
      const sectionFields = options.map((opt) =>
        value === opt.value
          ? { field, value, isChecked: false }
          : { field: opt.field, value: opt.value, isChecked: opt.isChecked },
      );
      setCascadingFiltersLocal([...cascadingFiltersLocalClone, ...sectionFields]);
    }
  };

  const numOfSectionItems = options?.length;
  const numOfSectionCheckedItems = getNumOfSectionCheckedItems(
    isFiltered ? sectionCascadingFiltersLocal : options,
    numOfSectionItems,
    options,
  );

  const isAllIntermediate =
    numOfSectionItems > numOfSectionCheckedItems && numOfSectionCheckedItems > 0;

  const isAllSectionItemsChecked =
    !isAllIntermediate && numOfSectionItems === numOfSectionCheckedItems;

  const setAllCheckbox = () => {
    const cascadingFiltersLocalClone = cloneAndRemoveUpstream({
      sectionFiled,
      cascadingFiltersLocal,
      cascadingFiltersConfig,
    });
    if (isEmpty(sectionCascadingFiltersLocal)) {
      const sectionFields = options.map((opt) => ({
        field: opt.field,
        value: opt.value,
        isChecked: false,
      }));
      setCascadingFiltersLocal([...cascadingFiltersLocalClone, ...sectionFields]);
    } else {
      const isOneFalse = !!find(sectionCascadingFiltersLocal, { isChecked: false });
      const otherSectionsFilters = filter(
        cascadingFiltersLocalClone,
        (item) => item.field !== sectionFiled,
      );
      const sectionFields = options.map((opt) => ({
        field: opt.field,
        value: opt.value,
        isChecked: isOneFalse,
      }));
      setCascadingFiltersLocal([...otherSectionsFilters, ...sectionFields]);
    }
  };

  const ListItemVirtualized = ({ index, key, style }) => {
    if (index === 0) {
      return (
        <CascadingFiltersListItemAll
          key={key}
          style={style}
          options={options}
          isAllSectionItemsChecked={isAllSectionItemsChecked}
          isAllIntermediate={isAllIntermediate}
          setAllCheckbox={setAllCheckbox}
        />
      );
    }
    const fieldObj = options[index - 1];
    if (fieldObj) {
      const isChecked = isCheckedHandler({
        sectionCascadingFiltersLocal,
        field: fieldObj.field,
        value: fieldObj.value,
      });

      return (
        <div style={style} key={key}>
          <CascadingFiltersListItem
            key={fieldObj.value}
            isChecked={isChecked}
            setCheckbox={() =>
              setCheckboxHandler({
                fieldObj,
                isChecked: isChecked,
              })
            }
            disableRipple
            size="small"
            itemText={getOption(fieldObj.displayName)}
          />
        </div>
      );
    }
  };
  ListItemVirtualized.propTypes = {
    index: PropTypes.number,
    key: PropTypes.string,
    style: PropTypes.object,
  };

  return (
    <>
      <List
        sx={{
          padding: 0,
          height: `${fixedHeight && 'calc(100vh - 22.5rem)'}`,
          width: isMobile ? '12rem' : '16rem',
        }}
      >
        {virtualizedList ? (
          <AutoSizer>
            {({ height }) => (
              <VirtualizedList
                height={height}
                width={isMobile ? 200 : 256}
                rowCount={options.length + 1}
                rowHeight={21}
                rowRenderer={ListItemVirtualized}
              />
            )}
          </AutoSizer>
        ) : (
          <>
            <CascadingFiltersListItemAll
              options={options}
              cascadingFiltersConfig={cascadingFiltersConfig}
              isAllSectionItemsChecked={isAllSectionItemsChecked}
              isAllIntermediate={isAllIntermediate}
              setAllCheckbox={setAllCheckbox}
            />
            {options.map((fieldObj) => {
              const isChecked = isCheckedHandler({
                sectionCascadingFiltersLocal,
                field: fieldObj.field,
                value: fieldObj.value,
              });
              return (
                <CascadingFiltersListItem
                  key={fieldObj.value}
                  isChecked={isChecked}
                  setCheckbox={() =>
                    setCheckboxHandler({
                      fieldObj,
                      isChecked: isChecked,
                    })
                  }
                  itemText={getOption(fieldObj.displayName)}
                />
              );
            })}
          </>
        )}
      </List>
    </>
  );
};

CascadingFiltersList.propTypes = {
  options: PropTypes.array,
  fixedHeight: PropTypes.bool,
  isFiltered: PropTypes.bool,
  setCascadingFiltersLocal: PropTypes.func,
  cascadingFiltersLocal: PropTypes.array,
  virtualizedList: PropTypes.bool,
  cascadingFiltersConfig: PropTypes.array,
  isCascadingFilterOff: PropTypes.bool,
};
