import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useProfileContext } from './UserProfile.context';

export const InlineFilters = createContext({});

const getInitialInlineFilters = (selectedUserProfile) =>
  get(selectedUserProfile, 'value.inline') || [];

const InlineFiltersProvider = ({ children }) => {
  const { selectedUserProfile, setFilters } = useProfileContext();

  const [inlineFilters, setInlineFilters] = useState(getInitialInlineFilters(selectedUserProfile));

  useEffect(() => {
    setInlineFilters(getInitialInlineFilters(selectedUserProfile));
  }, [selectedUserProfile]);

  const setInlineFiltersHandler = (filters) => {
    setInlineFilters(filters);

    setFilters({ filterType: 'inline', filters });
  };

  const contextValue = useMemo(
    () => ({ inlineFilters, setInlineFilters: setInlineFiltersHandler }),
    [inlineFilters, setInlineFiltersHandler],
  );

  return <InlineFilters.Provider value={contextValue}>{children}</InlineFilters.Provider>;
};

InlineFiltersProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InlineFiltersProvider;

export const useInlineFilterContext = () => useContext(InlineFilters);
