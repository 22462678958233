import { filter } from 'lodash';
import { truncateText } from '../../utils/general.js';

const mapSectionChipLabel = ({ displayName }) => {
  switch (displayName.toUpperCase()) {
    case 'UNITED STATES':
      return 'USA';
    default:
      return displayName;
  }
};

export const getLabelForChip = ({ headerName, options }) => {
  const numOfSectionItems = options?.length;
  const sectionCheckedItems = filter(options, { isChecked: true });
  const numOfSectionCheckedItems = sectionCheckedItems?.length;

  const isAllIntermediate =
    numOfSectionItems > numOfSectionCheckedItems && numOfSectionCheckedItems > 0;
  const isAllSectionItemsChecked =
    !isAllIntermediate && numOfSectionItems === numOfSectionCheckedItems;

  if (isAllSectionItemsChecked) {
    return 'ALL';
  }
  if (numOfSectionCheckedItems === 1) {
    const MAX_CHAR = 22;
    const displayName = sectionCheckedItems[0]?.displayName;
    const sectionChipLabel = displayName ? mapSectionChipLabel({ displayName }) : '';
    const maxLength = MAX_CHAR - headerName.length;
    return truncateText({ text: sectionChipLabel, maxLength }).toUpperCase();
  }
  return numOfSectionCheckedItems;
};
